

const Project = () => {
    return (
        <div >
           
            <div className="project-end">
                <div style={{fontSize:"4.036vw"}}>Ready For Your Project?</div>
                <a href="/contact">Get in Touch</a>
            </div>
        </div>
    );
}
export default Project;