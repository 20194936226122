import engage from '../assets/ad-engage.png';
import tech from '../assets/services-tech.png';
import whyadworks from '../assets/whyadworks.jpg';
import contentdesign from '../assets/content-design.png';
import copywriting from '../assets/copy-writing.png';
import hashtag from '../assets/hashtag-research.png';
import googleAds from '../assets/GoogleAds.png'
import facebookAds from '../assets/facebook.png'
import googleshopping from '../assets/GoogleShopping.png'
import localSeo from '../assets/localSeo.png'
import ecommerce from '../assets/ecommerce.png'
import ppc from '../assets/ppc.png'

const Intro = ()=>{
    return (
        <div className="intro" style={{backgroundColor:"white"}}>
              <br/>
              <div style={{textAlign:"center"}}>
                <div className="welcometo" style={{marginTop:"2vw",fontSize:"1.302vw"}}>WELCOME TO </div>
                <div className="adworks" style={{fontSize:"5.208vw"}}><strong style={{color:"#d4b508"}}>AI</strong><span>POD</span></div>
               
              </div>
              <img src={whyadworks}/>

              <div className="brief brief2"><br/>
                <div className="homeheadings" style={{marginTop:"5vw",fontSize:"1.302vw",color:"#d4b508"}}><strong>WHY AIPOD?</strong></div>
                  <div className="whytext" >AIPOD is your One Stop Solution for all your advertisement needs. 
                  Through the use of AI, our expert team of designers and marketers make sure that your brand has a strong and positive social media presence
                  </div>
              </div>
              <img className='imgformobile' src={whyadworks}/>
              <div className="brief ">
                <div className="homeheadings" style={{marginTop:"5vw",fontSize:"1.302vw",color:"#d4b508"}}><strong>OUR SERVICES</strong></div>
                <div className="servicesintro">
                  We mainly focus on professional social media management and running digital ads on platforms such as Facebook, Instagram And Google. Everything from scratch from content ideation , designing and final reach analysis is done by our in-house team of experts.                
                   &nbsp;&nbsp;

                  <br/><br/>
                  We specialize in : <br/><br/>
                  
                  <div style={{marginLeft:"2.5vw",marginTop:"2vw"}}>
                      <div className="services-flex-1" style={{marginBottom:"1vw"}}>
                      <div className="services-flex-item">
                          <img className='services-card-image' src={googleAds}  />
                          <div class="services-card-title">Google Ads</div>We create a profit-over-traffic approach Google Ads management that is scaleable as well
                        </div>
                        <div className="services-flex-item">
                          <img className='services-card-image' src={facebookAds}  />
                          <div class="services-card-title">Facebook Ads </div>We create scalable and profitable paid social campaigns targeting the right audience
                        </div>
                        <div className="services-flex-item">
                          <img className='services-card-image' src={googleshopping}  />
                          <div class="services-card-title">Google Shopping </div>We optimise feed and create fluid P.Max campaigns to maximise profit
                        </div>
                    
                   
                        <div className="services-flex-item">
                          <img className='services-card-image' src={localSeo}  />
                          <div class="services-card-title">Local SEO</div>We create a profit-over-traffic approach Google Ads management that is scaleable as well
                        </div>
                        </div>
                        <div className="services-flex-1" style={{marginBottom:"1vw"}}>
                        <div className="services-flex-item">
                          <img className='services-card-image' src={contentdesign}  />
                          <div class="services-card-title">Content Design </div>We use AI to generate attractive text, post info and website content 
                        </div>
                        <div className="services-flex-item">
                        <img className='services-card-image' style={{width:"70%"}} src={ecommerce}  />
                        <div class="services-card-title">ECommerce Seo</div>We make sure your keywords are in the right places which offer a high return on investment (ROI)
                        </div>
                        <div className="services-flex-item">
                        <img className='services-card-image' src={engage} />
                        <div className="services-card-title">Engagement</div>
                        We create attractive ads to engage a larger audience for your business
                        </div>
                        <div className="services-flex-item">
                        <img className='services-card-image' src={ppc} />
                        <div className="services-card-title">PPC</div>
                        We target each ad to a specific audience
                        </div>
                      </div>
                    
                      
                      
                  </div>
                </div>
              </div>
            </div>
    );
}
export default Intro;