import { useEffect,useState, useRef } from "react";

import slide from '../assets/slide.png';

const Landing = () => {

  //Images for each slide
  const images = [
  slide
];

const text=[<div className="slidetext slide2">
USING<br/>
 <span >ADVANCED AI</span> <br/> TO CATER<br/> ALL YOUR<br/> MARKETING NEEDS</div>,

];
  /*
    Extra images links :
    https://cdn.pixabay.com/photo/2022/05/18/07/07/laptop-7204537__340.jpg
    https://imageio.forbes.com/specials-images/imageserve/980428134/0x0.jpg?format=jpg&width=1200
    
  */ 
  const delay = 3000;

  //text divs to display on slides
  
 
  function Slideshow() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
      
    return () => {
      resetTimeout();
    };
  }, [index]);


  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {images.map((x, index) => (
          <div>
        
          <div
            className="slide"
            key={index}
          
            // style={Object.assign(styles[index],{backgroundImage:`url(${x})`})}
            style={{backgroundImage:`url(${x})`}}
          >
          
           </div> 
          </div>
        ))}
      </div>

   
    </div>
  );
}
    return (
          <Slideshow/>
    );
}
export default Landing;