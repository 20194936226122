import Landing from "./landingscreen";
import Project from "./projectonhome";
import Intro from "./intro";
import "./home.css";

import Register from "./Register";
const Home = () => {
  return (
    <div>
  
    <div className="text-yellow-600">
    <h1 className="text-center ">We help to grow your business</h1>

    </div>
<Landing />
      <Intro />
      <Project />
      
    </div>
  );
};
export default Home;
